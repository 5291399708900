import { default as gamembiG6dmYEvMeta } from "/home/gitlab/builds/M7oWr7VE/0/scratch-book/deploy/app/pages/game.vue?macro=true";
import { default as indexlf6SF5Z806Meta } from "/home/gitlab/builds/M7oWr7VE/0/scratch-book/deploy/app/pages/index.vue?macro=true";
import { default as private_45policyaYxY7P7OxnMeta } from "/home/gitlab/builds/M7oWr7VE/0/scratch-book/deploy/app/pages/private-policy.vue?macro=true";
import { default as rank9kYrGHkS0DMeta } from "/home/gitlab/builds/M7oWr7VE/0/scratch-book/deploy/app/pages/rank.vue?macro=true";
import { default as settinghQ0xmBbF89Meta } from "/home/gitlab/builds/M7oWr7VE/0/scratch-book/deploy/app/pages/setting.vue?macro=true";
import { default as shareLZDGxsnkQ3Meta } from "/home/gitlab/builds/M7oWr7VE/0/scratch-book/deploy/app/pages/share.vue?macro=true";
import { default as termsn0WDGIUkf1Meta } from "/home/gitlab/builds/M7oWr7VE/0/scratch-book/deploy/app/pages/terms.vue?macro=true";
import { default as tutorial3e8e6i5DmwMeta } from "/home/gitlab/builds/M7oWr7VE/0/scratch-book/deploy/app/pages/tutorial.vue?macro=true";
export default [
  {
    name: "game",
    path: "/game",
    component: () => import("/home/gitlab/builds/M7oWr7VE/0/scratch-book/deploy/app/pages/game.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/gitlab/builds/M7oWr7VE/0/scratch-book/deploy/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "private-policy",
    path: "/private-policy",
    component: () => import("/home/gitlab/builds/M7oWr7VE/0/scratch-book/deploy/app/pages/private-policy.vue").then(m => m.default || m)
  },
  {
    name: "rank",
    path: "/rank",
    component: () => import("/home/gitlab/builds/M7oWr7VE/0/scratch-book/deploy/app/pages/rank.vue").then(m => m.default || m)
  },
  {
    name: "setting",
    path: "/setting",
    component: () => import("/home/gitlab/builds/M7oWr7VE/0/scratch-book/deploy/app/pages/setting.vue").then(m => m.default || m)
  },
  {
    name: "share",
    path: "/share",
    component: () => import("/home/gitlab/builds/M7oWr7VE/0/scratch-book/deploy/app/pages/share.vue").then(m => m.default || m)
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/home/gitlab/builds/M7oWr7VE/0/scratch-book/deploy/app/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: "tutorial",
    path: "/tutorial",
    component: () => import("/home/gitlab/builds/M7oWr7VE/0/scratch-book/deploy/app/pages/tutorial.vue").then(m => m.default || m)
  }
]