import revive_payload_client_4sVQNw7RlN from "/home/gitlab/builds/M7oWr7VE/0/scratch-book/deploy/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/gitlab/builds/M7oWr7VE/0/scratch-book/deploy/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/gitlab/builds/M7oWr7VE/0/scratch-book/deploy/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/gitlab/builds/M7oWr7VE/0/scratch-book/deploy/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/gitlab/builds/M7oWr7VE/0/scratch-book/deploy/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/gitlab/builds/M7oWr7VE/0/scratch-book/deploy/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/gitlab/builds/M7oWr7VE/0/scratch-book/deploy/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/gitlab/builds/M7oWr7VE/0/scratch-book/deploy/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/gitlab/builds/M7oWr7VE/0/scratch-book/deploy/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import globalApiPlugin_4LPZyF3CaW from "/home/gitlab/builds/M7oWr7VE/0/scratch-book/deploy/app/plugins/globalApiPlugin.ts";
import grag_client_jnQY9uLEoM from "/home/gitlab/builds/M7oWr7VE/0/scratch-book/deploy/app/plugins/grag.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  globalApiPlugin_4LPZyF3CaW,
  grag_client_jnQY9uLEoM
]